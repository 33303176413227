import { CollapsedAreaLabels, CollapseTrigger, CommonArea, TopBottomPadding } from 'Components/Common';
import FormFloatingLabel from 'Components/FloatingInput';
import MaterialDesignSwitch from 'Components/MaterialSwitch';
import { FormikProvider } from "formik";
import { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import styled from 'styled-components';

export const SalesBaboonSettings = (props: {
    formik: FormikType<FormLeadDelivery>;
}) => {
    const { formik } = props;
    const [showDiv, setShowDiv] = useState(false);

    return (
        <FormikProvider value={formik}>
            <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                <TopBottomPadding>
                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapseTriggerHeading className='d-flex align-items-center'>
                            <img src='/salesbaboonicon.jpeg' className='img-fluid me-1' width={40} />
                            Sales Baboon Settings
                        </CollapseTriggerHeading>

                        <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                        </CommonArea>

                    </div>
                </TopBottomPadding>
            </CollapseTrigger>
            {
                showDiv && <CollapsedArea className='bg-white' id={"example-collapse-text_ss"}>
                    <TopBottomPadding>

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Enable
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform `}>
                                <MaterialDesignSwitch
                                    defaultchecked={formik.values?.salesBaboonConfig?.enabled || false}
                                    checked={(checked) => formik.setValues({
                                        ...formik.values,
                                        salesBaboonConfig: {
                                            enabled: checked
                                        }
                                    })} />
                            </div>
                        </div>
                        {
                            formik.values.salesBaboonConfig.enabled && <div className='mt-2 border-top'>
                                <TopBottomPadding className='pb-0 mb-2'>

                                    <div className='row noMArginOnLast'>
                                        <div className='col-md-12'>
                                            <FormFloatingLabel
                                                onInput={e => formik.setValues({
                                                    ...formik.values,
                                                    salesBaboonConfig: {
                                                        ...formik.values.salesBaboonConfig,
                                                        apiKey: e
                                                    }
                                                })
                                                }
                                                label={"Api Key"}
                                                defaultValue={formik.values.salesBaboonConfig.apiKey} />
                                        </div>
                                    </div>
                                </TopBottomPadding>
                            </div>
                        }

                    </TopBottomPadding>
                </CollapsedArea>
            }
        </FormikProvider>
    );
}

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`

const BlueBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(65, 112, 251);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 51px;

`
const RemoveBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(251, 104, 93);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 27px;

`