import { SideBarHeaderWrapper } from 'Components/Common';
import styled from 'styled-components';
import LeadNotifications from './LeadNotifications';

import { useFormik } from 'formik';
import { CallAxios } from 'Helpers';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import { SalesBaboonSettings } from './SalesBaboonSettings';


const StepConfigName = styled.div`
background: rgb(247, 247, 247);
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    padding: 18px 25px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
`

const LeadDeliveryTab = () => {
    const { formId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const leadDelivery = useSelector((state: AppStore) => state.editor.leadDelivery);

    const formik = useFormik<FormLeadDelivery>({
        initialValues: {
            emailAddress: leadDelivery.emailAddress,
            mobilePhoneNumber: leadDelivery.mobilePhoneNumber,
            salesBaboonConfig: leadDelivery.salesBaboonConfig
        },
        onSubmit: values => {
            CallAxios('/api/Forms/SaveStepData|PATCH',
                {
                    Data: JSON.stringify(values),
                    FormId: formId,
                    Step: 'LeadDelivery'
                },
                undefined,
                undefined,
                navigate,
                dispatch
            ).then(res => {
                toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
            })
        },
    })

    React.useEffect(() => {
        formik.resetForm({
            values: {
                ...leadDelivery,
                salesBaboonConfig: leadDelivery.salesBaboonConfig || {
                    enabled: false
                },
            }
        });
    }, [leadDelivery]);
    return (
        <>
            <SideBarHeaderWrapper>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='mb-0 fw-bold'>Lead Delivery</h6>
                    <button className='btn btn-primary btn-sm' onClick={formik.handleSubmit as any}> Save changes</button>
                </div>
            </SideBarHeaderWrapper>
            <div className='CollapseAbleWrapper'>
                <StepConfigName>Lead Notifications</StepConfigName>

                <LeadNotifications formik={formik} />
            </div>
            <div className='CollapseAbleWrapper'>
                <StepConfigName>CRM Settings</StepConfigName>

                <SalesBaboonSettings formik={formik} />
            </div>

        </>
    );
}

export default LeadDeliveryTab;






