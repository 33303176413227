import { createSlice } from '@reduxjs/toolkit';

const initialState: FormsEditorOptions = {
  activeNavBar: 'Design',
  isLoading: false,
  responseOpen: false,
  stepsSubmitted: [],
  defaultResponses: [],
  stepOptions: {},
  steps: [],//FormEditorStep
  designOptions: {
    templateStyle: {
      templateStyle: { value: 'EnhancedForm', label: 'Enhanced Form' },
      templateBgColor: "",
      templateBgimg: "",
    },
    header: {
      hideHeader: false,
      headlineText: "",
      headlineBgColor: "",
      headlineBgimg: "",
      headlineFontColor: "#000",
      headlineFontSize: '',
      headlineFontWeight: { value: 700, label: '700' },
    },
    stepHeadingAndSubHeading: {
      stepHeadingFontColor: "",
      stepHeadingFontSize: "",
      stepHeadingFontWeight: { value: 600, label: '600' },
      stepSubHeadingFontColor: "",
      stepSubHeadingFontSize: "",
      stepSubHeadingFontWeight: { value: 700, label: '700' },
    },
    responseStyling: {
      responseGridWidth: { value: 12, label: '12' },
      responseFontSize: '',
      responseTextAlign: { value: 'center', label: 'Center' },
      responseFontWeight: { value: 400, label: '400' },
      responseMarginBottom: '',
      responseVerticalPadding: '',
      responseBgColor: '',
      responseHoverBgColor: '',
      responseBorderColor: '',
      responseFontColor: '',
      responseHoverFontColor: '',
      responseBoxMaxWidth: "",
      responseBoxMaxWidthOnMobile: "",
      multiselectColor:"",
      multiselectTextColor:"",
      multiselectSelectedColor:"",
      multiselectSeletedTextColor:""
    },
    loadingSpinner: {
      loadingSpinnerColor: "#fff",
      loadingSpinnerSzie: "",
      loadingSpinnerThickness: ""
    },
    callUsBar: {
      topCallUsBar: false,
      bottomCallUsBar: false,
      callUsBarText: '',
      callUsBarPhone: '',
      callUsBarBgColor: '',
      callUsBarFontColor: '',
      callUsBarFontSize: '',
      callUsBarFontWeight: { value: 400, label: '400' },
    },
    backButton: {
      backBtn: false,
      backBtnBgColor: "",
      backBtnHoverBgColor: "",
      backBtnBorderRadius: "",
      backBtnVerticalPadding: "",
      backBtnWidthAuto: false,
      backBtnWidth: "100",
      backBtnHorizontalPadding: "",
      backBtnHorizontalPosition: { value: "center", label: 'Middle' },
      backBtnVerticalPosition: { value: "top", label: 'Top' },
      backBtnFontColor: "",
      backBtnText: "Back",
      backBtnFontSize: "",
      backBtnFontWeight: { value: 400, label: '400' },
    },
    onSiteMessage: {
      onSiteMessageMaxWidth: "",
      onSiteMessageMaxWidthOnMobile: "",
    },
    leadFormDimensions: {
      formHeight_popup: "",
      formWidth_popup: "",
      formHeightonMobile_popup: "",
      formWidthonMobile_popup: "",
    },
    offerHeadline: {
      offerHeadlineText: "",
      offerHeadlineFontColor: "",
      offerHeadlineFontSize: "",
      offerHeadlineFontWeight: { value: 400, label: '400' }
    },
    submitButton: {
      submitButtonWidth: "",
      submitButtonWidthMobile: "",
      submitButtonColor: "",
      submitButtonColorOnHover: "",
      submitButtonBorderColor: "",
      submitButtonTextColor: "",
      submitButtonTextColorOnHover: "",
      submitButtonFontSize: "",
      submitButtonFontSizeOnMobile: "",
      submitButtonFontWeight: { value: 400, label: '400' },
      submitButtonAdditionalTextColor: "",
      submitButtonBorderRadius: "",
    },
    thankyouStep: {
      thankyouStepFontSize: "",
      thankyouStepFontSizeOnMobile: "",
      thankyouStepFontWeight: { value: 400, label: '400' },
      thankyouStepFontColor: "",
      thankyouStepDarkCloseButton: false,
    },
    progressBar: {
      progressBar: false,
      progressBarColor: "",
      progressBarBgColor: "",
      progressBarFontColor: "",
    },
    otherFontStyling: {
      linkColor: "",
      generalTextColor: "",
      socialProof: false,
      socialProofDefaultCount: "",
      socialProofLabel: "",
      socialProofText: "",
      socialProofTextColor: "",
      tcpNoticeTitleColor: "",
      tcpNoticeHandlerColor: "",
      tcpNoticeTextBackgroundColor: "",
      tcpNoticeTextColor: "",
      tcpNoticeTextAlign: { value: 'center', label: 'Center' },
    }

  },
  settings: {
    profileEmail: { value: '', label: '' },
    FormName: "",
    chooseMode: { value: 'popup', label: 'Popup' },
    timedPopup: false,
    popupOnDevices: { value: 'all', label: 'All' },
    popupDelay: 1,
    mobileSpecificDelay: false,
    mobilePopupDelay: "",
    popupFrequency: { value: 'every_visit', label: 'Every Visit' },
    exitPopup: false,
    onSiteMessage: false,
    onSiteMessageOnMobile: false,
    onSiteMessagePosition: { value: 'bottom_right', label: 'Bottom Right' },
    displayMessage: false,
    appearanceDelay: "",
    displayMessageOnMobile: false,
    appearanceDelayOnMobile: "",
    displayMessageText: "",
    hideAvatarhere: false,
    hideAvatarAfterStepOne: false,
    hideAvatar_Embedded: false,
    AvatarImage: "",
    autofillZipCode: false,
    dropoffMode: false,
    googleAnalyticsTrackingID: "",
    ga4MeasurementID: "",
    LeadValue: "",
    reCAPTCHASiteKey: "",
    questionPromptText: "",
    formField_Required_ErrorText: "",
    formField_WrongEmail_ErrorText: "",
    formField_WrongZipCode_ErrorText: "",
    formField_WrongCanadianPostalCode_ErrorText: "",
    formField_WrongUKPostalCode_ErrorText: "",
    formField_WrongPhone_ErrorText: "",
    formField_WrongFullName_ErrorText: "",
    formField_WrongPassword_ErrorText:""
  },
  leadDelivery: {
    emailAddress: [],
    mobilePhoneNumber: [],
    salesBaboonConfig: {
      enabled: false,
      apiKey: ''
    }
  }
};

export const PreviewPage = createSlice({
  name: 'PreviewPage',
  initialState,
  reducers: {
    setAddEditResponseWindow: (state, action: IAction<boolean>) => {
      state.responseOpen = action.payload;
    },
    setDesignOptions: (state: FormsEditorOptions, action: IAction<FormsDesignOptions>) => {
      state.designOptions = { ...action.payload }
    },
    setStepOptions: (state: FormsEditorOptions, action: IAction<FormSteps>) => {
      state.stepOptions = { ...state.stepOptions, ...action.payload }
    },
    setSettings: (state: FormsEditorOptions, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setStepfromJSON: (state: FormsEditorOptions, action: IAction<FormSteps[]>) => {
      state.steps = action.payload;
    },
    addSubmittedResponse: (state: FormsEditorOptions, action: IAction<SubmittionType>) => {
      state.stepsSubmitted?.push(action.payload);
      state.backButtonClickable = true;
    },
    removeSubmittedResponse: (state: FormsEditorOptions, action: IAction<SubmittionType>) => {
      if (state.stepsSubmitted) {
        const steps = [...state.stepsSubmitted];
        if (steps) {
          const index = steps.findIndex(x => x.id == action.payload.id);
          if (index > -1) {
            steps.splice(index, 1);
            state.stepsSubmitted = steps;
          }
          if (!(steps.length > 0))
            state.backButtonClickable = false;
        }
      }
    },
  },
})

export const {
  setDesignOptions,
  setStepOptions,
  setStepfromJSON,
  setSettings,
  addSubmittedResponse,
  removeSubmittedResponse,
  setAddEditResponseWindow
} = PreviewPage.actions

export default PreviewPage.reducer