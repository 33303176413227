import { SubmitBtn,BackBtn } from "Pages/Forms/Builder/Components";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import React from "react";
import {
  FormDatepicker,
  FormSelect,
  FormSlider,
  PhoneNumber,
  Text,
} from "../../FormFields";
import { MultiFormCheckboxes } from "../../FormFields/InputTypes/multiSelectCheckboxes";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { setStepOptions } from "Store/slices/PreviewPageSlice";


export const FormFields = (props: {
  submitButtonStyle: any;
  backButtonStyle: any;
  multiSelectstyle:any;
  currentSlice: StoreSlices;
  onSubmit: SubmitHandler<any>;
  formMain: UseFormReturn<any, any>;
}) => {
  const {
    submitButtonStyle,
    backButtonStyle,
    multiSelectstyle,
    currentSlice,
    onSubmit,
    formMain,
    formMain: { handleSubmit },
  } = props;

  const dispatch = useDispatch();

  const stepOptions = useSelector(
    (state: AppStore) => state[currentSlice].stepOptions
  );
  useEffect(() => {
    console.log(stepOptions);
  }, [stepOptions]);

  
  const LoadComponent = (x: FormStepResponses, i: number) => {
    
    return {
      Text: (
        <Text
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
          as="input"
        />
      ),
      LongText: (
        <Text
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
          as="textarea"
        />
      ),
      Select: (
        <FormSelect
          isMulti={false}
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
      // "MultiSelect": <FormSelect isMulti={true} formMain={formMain} data={x} key={'com_' + i} currentSlice={currentSlice} />,
      MultiSelect: (
        <MultiFormCheckboxes
          multiSelectstyle={multiSelectstyle} 
          checkboxStyle={submitButtonStyle}
          isMulti={true}
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
      Slider: (
        <FormSlider
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
      Calendar: (
        <FormDatepicker
          formMain={formMain}
          data={x}
          portal={true}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
      Phone: (
        <PhoneNumber
          formMain={formMain}
          data={x}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
      Date: (
        <FormDatepicker
          formMain={formMain}
          data={x}
          portal={false}
          key={"com_" + i}
          currentSlice={currentSlice}
        />
      ),
    }[x.fieldType];
  };


  const formRepeater = () => { 
    debugger
    if(stepOptions?.responses && stepOptions?.responses?.length > 0){
      let prevCopy : any = {...stepOptions};
      let original : any = [...prevCopy.responses];
      let newList : any = prevCopy?.responses?.map((x:any) => {
        return {...x,id:uuid()}
    });
      dispatch(setStepOptions({...stepOptions, responses: [...original,...newList] }))
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (e) => {
        toast("Please fill all required fields.", { type: "warning" });
      })}
    >
      {stepOptions?.responses?.map((x, i) => LoadComponent(x, i))}

      {stepOptions?.isRepeatable && 
         <BackBtn
        type="button"
        bgColor={backButtonStyle.backBtnBgColor}
        bgColorOnHover={backButtonStyle.backBtnHoverBgColor}
        borderRadius={backButtonStyle.backBtnBorderRadius}
        py={backButtonStyle.backBtnVerticalPadding}
        widthOrPadding={backButtonStyle.backBtnWidthAuto}
        backBtnWidth={backButtonStyle.backBtnWidth}
        backBtnHorizontalPadding={backButtonStyle.backBtnHorizontalPadding}
        fc={backButtonStyle.backBtnFontColor}
        fs={backButtonStyle.backBtnFontSize}
        fw={backButtonStyle.backBtnFontWeight}
        onClick={formRepeater}
        >
        <FaPlus scale={.7} /> Add Another
    </BackBtn>
      }
      <SubmitBtn
        width={submitButtonStyle?.submitButtonWidth}
        br={submitButtonStyle?.submitButtonBorderRadius}
        fw={submitButtonStyle?.submitButtonFontWeight}
        fcOnHover={submitButtonStyle?.submitButtonTextColorOnHover}
        fc={submitButtonStyle?.submitButtonTextColor}
        bc={submitButtonStyle?.submitButtonBorderColor}
        bgColorOnHover={submitButtonStyle?.submitButtonColorOnHover}
        fs={submitButtonStyle?.submitButtonFontSize}
        bgColor={submitButtonStyle?.submitButtonColor}
      >
        {stepOptions?.submitButtonLabel
          ? stepOptions.submitButtonLabel
          : `Edit "Submit Button Label"`}
      </SubmitBtn>
    </form>
  );
};
