import { ValidationRule } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
    InvalidEmail,
    InvalidFullName,
    InvalidNumbers,
    InvalidPassword,
    InvalidPhone,
    InvalidZipCode,
    RequiredMessage,
    UnMatchEmail
} from "./Messages";
import {
    EmailRegex,
    FullNameRegex,
    NumberRegex,
    ZipCodeRegex,
    PasswordRegex
} from "./Regex";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const RequiredRule = (isRequired: boolean, errorMessage?: string): ValidationRule<boolean> => {
    return {
        value: isRequired,
        message: isRequired ? (errorMessage || RequiredMessage) : ""
    }
}

export const EmailRule = (email: string, errorMessage?: string): string | boolean => {
    return EmailRegex.test(email) ? true : (errorMessage || InvalidEmail);
}

export const FullNameRule = (name: string, errorMessage?: string): string | boolean => {
    return FullNameRegex.test(name) ? true : (errorMessage || InvalidFullName);
}

export const NumericRule = (numericValue: string | number, errorMessage?: string): string | boolean => {
    return NumberRegex.test(numericValue.toString()) ? true : (errorMessage || InvalidNumbers);
}

export const ZipCodeRule = (numericValue: string | number, errorMessage?: string): string | boolean => {
    return ZipCodeRegex.test(numericValue.toString()) ? true : (errorMessage || InvalidZipCode);
}

export const PhoneValidationRule = (phone: string, errorMessage?: string) => {
    return isValidPhoneNumber(phone) ? true : (errorMessage || InvalidPhone);
}

export const PasswordRule = (password: string, errorMessage?: string): string | boolean => {
    return PasswordRegex.test(password) ? true : (errorMessage || InvalidPassword);
}

export const MatchEmailRule = (email: string, email2:string , errorMessage?: string): string | boolean => {
    return EmailRegex.test(email2) ? (email==email2) ? true : (errorMessage || UnMatchEmail) : (errorMessage || InvalidEmail);
}


export const FieldValidators = (v: string, id: string, emailId:string, isAccountEmail:boolean, accountEmail:string, validationType: ValidationTypes, settings?: FormEditorOptionsSettings) => {
    
    //Get Account Email value from Store
    
    switch (validationType) {
        case 'Email':
            if(isAccountEmail==true && emailId!=id && accountEmail!=""){
                return  MatchEmailRule(accountEmail,v,  UnMatchEmail);
            }
            else{
                return EmailRule(v, settings?.formField_WrongEmail_ErrorText);
            }
        case 'FullName':
            return FullNameRule(v, settings?.formField_WrongFullName_ErrorText);
        case 'Number':
            return NumericRule(v);
        case 'PhoneNumberValidation':
            return PhoneValidationRule(v, settings?.formField_WrongPhone_ErrorText);
        case 'Zipcode':
            return ZipCodeRule(v, settings?.formField_WrongZipCode_ErrorText);
        case 'Password':
                return PasswordRule(v, settings?.formField_WrongPassword_ErrorText);
        default:
            return undefined;
    }
};

export const FieldRegEx = (validator: ValidationTypes) => {
    switch (validator) {
        case 'Email':
            return EmailRegex;
        case 'FullName':
            return FullNameRegex;
        case 'Number':
            return NumberRegex;
        case 'PhoneNumberValidation':
            return undefined;
        case 'Zipcode':
            return ZipCodeRegex;
        case 'Password':
                return PasswordRegex;   
        default:
            return undefined;
    }
}
